import React, { FC, ButtonHTMLAttributes } from 'react'
import styles from './Button.module.css'
import clsx from 'clsx'

type Props = {
  children?: React.ReactNode
  className?: string
  buttonSize?: 'large' | 'medium' | 'small'
  variant?: 'primary' | 'secondary' | 'outline' | 'transparent' | 'link'
} & ButtonHTMLAttributes<HTMLButtonElement>

/**
 * A basic button component with optional variant and className.
 *
 * @param {Props} props
 * @prop {ReactNode} [children] - The content to be rendered inside the button.
 * @prop {'primary' | 'secondary' | 'outline' | 'transparent' | 'link'} [variant] - The variant of the button.
 * @prop {'large' | 'medium' | 'small'} [buttonSize] - The size of the button.
 * @prop {string} [className] - Additional CSS class names to be applied to the button.
 * @returns {ReactElement} - The button element.
 */
const Button: FC<Props> = ({
  children,
  variant = 'primary',
  buttonSize = 'medium',
  className,
  ...props
}) => {
  return (
    <button
      className={clsx(styles.button, styles[variant], styles[buttonSize], className)}
      aria-label={(props['aria-label'] && props['aria-label'] !== '') ? props['aria-label'] : (typeof children === 'string') ? children : undefined}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button