import React, { FC, HTMLAttributes, ReactElement } from 'react'
import styles from './Link.module.css';
import clsx from 'clsx';
import Link from 'next/link';
import { AssetStoryblok, MultilinkStoryblok, NavigationLinkStoryblok, NestedLinkStoryblok } from '@/app/types/component-types-sb';
import Image from '../image';

type Props = {
    override_label?: string
    children?: React.ReactNode
    asButton?: boolean
    buttonSize?: 'large' | 'medium' | 'small'
    size?: {
        width: number
        height: number
    }
    storyBlokLink?: boolean
    image?: AssetStoryblok
    prefetch?: boolean
    icon?: React.ReactNode
    link?: Partial<NavigationLinkStoryblok | MultilinkStoryblok | NestedLinkStoryblok>
    className?: string
    rel?: string
    href?: string
    target?: string
    variant?: 'primary' | 'secondary' | 'outline' | 'transparent' | 'link'
    color?: 'white' | 'red'
}

/**
 * A custom link component that can render as a Next.js Link or a regular <a> tag.
 *
 * @param {Props} props
 * @prop {string} [override_label] - Custom label to override default link text.
 * @prop {React.ReactNode} [children] - Content to be rendered inside the link.
 * @prop {boolean} [asButton=false] - Renders the link with button styles if true.
 * @prop {AssetStoryblok} [image] - Optional image to render within the link.
 * @prop {boolean} [prefetch=true] - Uses Next.js Link for prefetching when true.
 * @prop {'large' | 'medium' | 'small'} [buttonSize] - The size of the button.
 * @prop {React.ReactNode} [icon] - Optional icon to render within the link.
 * @prop {Partial<NavigationLinkStoryblok | MultilinkStoryblok | NestedLinkStoryblok>} [link] - Storyblok link object.
 * @prop {string} [className] - Additional CSS class names to apply.
 * @prop {string} [href] - Fallback URL if no link is provided.
 * @prop {'primary' | 'secondary' | 'outline' | 'transparent' | 'link'} [variant='primary'] - Variant style of the link if asButton is true.
 * @returns {ReactElement} - The rendered link element.
 */
const CustomLink: FC<Props & HTMLAttributes<HTMLAnchorElement>> = ({
    override_label,
    link,
    variant = 'primary',
    icon,
    children,
    buttonSize = 'medium',
    size = {
        width: 20,
        height: 20,
    },
    color = 'red',
    prefetch = true,
    storyBlokLink = false,
    className,
    image,
    asButton = false,
    rel,
    href,
    ...props
}: Props): ReactElement => {

    /**
     * Returns the href value of the link.
     * If storyBlokLink is true, uses the cached_url from the link object.
     * If storyBlokLink is false, uses the cached_url from the link.link object.
     * If neither exists, returns href or an empty string.
     * @returns {string} The href value.
     */
    const getHref = (): string => {
        if (storyBlokLink) {
            if (link && link.cached_url) return (checkPrefetch() ? '/' : '') + link?.cached_url
        } else {
            if (link && link.link && link.link.cached_url) return (checkPrefetch() ? '/' : '') + link?.link?.cached_url
        }

        return href || ''
    }

    /**
     * Checks if prefetching is enabled.
     * If prefetch is true, returns true.
     * If prefetch is false, checks if the link is a story and returns true if so.
     * Otherwise, returns false.
     * @returns {boolean} Whether prefetching is enabled.
     */
    const checkPrefetch = (): boolean => {
        if (prefetch) return true
        else {
            if (link?.link) if (link?.link.linktype === 'story') return true
            else if (link?.linktype === 'story') return true
            else return false

            return false
        }
    }

    /**
     * Returns the title value of the link.
     * If override_label is provided, uses that.
     * If storyBlokLink is true, uses the name from the link.story object.
     * If storyBlokLink is false, uses the name from the link.link.story object.
     * If neither exists, returns href or an empty string.
     * @returns {string} The title value.
     */
    const getLinkTitle = (): string => {

        if (link && link.link_text) return link.link_text

        if (override_label) return override_label

        if (link && link.story && link.story.name) return link.story.name

        if (link && link?.cached_url) return link?.cached_url

        return href || ''
    }

    return (
        <>
            {prefetch ? (
                <Link
                    href={getHref()}
                    {...props}
                    rel={(link?.relationship && link.relationship.trim() !== "") ? link.relationship : rel}
                    {...((link?.opens_new_tab && (link.opens_new_tab === true)) && { target: '_blank' })}
                    {...((link?.download && (link.download === true)) && { download: true })}
                    className={clsx(
                        {
                            [styles.button]: asButton === true,
                            [styles[`${variant}-${color}`]]: asButton === true,
                            [styles[buttonSize]]: asButton === true,
                            [styles.linkStyle]: asButton === false,
                        },
                        className
                    )
                    }
                >
                    {children ? children : (
                        <>
                            {(image && image.filename && image.filename.trim() !== "") && (
                                <div style={{
                                    width: size.width + "px",
                                    height: size.height + "px",
                                    position: 'relative'
                                }}>
                                    <Image asset={image} size={{
                                        width: size.width,
                                        height: size.height
                                    }} />
                                </div>
                            )}
                            {getLinkTitle()}
                            {icon && icon}
                        </>
                    )}
                </Link >
            ) : (
                <a
                    href={getHref()}
                    {...props}
                    rel={rel}
                    className={clsx(
                        {
                            [styles.button]: asButton === true,
                            [styles[`${variant}-${color}`]]: asButton === true,
                            [styles[buttonSize]]: asButton === true,
                            [styles.linkStyle]: asButton === false,
                        },
                        className
                    )
                    }
                >
                    {children ? children : (
                        <>
                            {(image && image.filename && image.filename.trim() !== "") && (
                                <div style={{
                                    width: '20px',
                                    height: '20px',
                                    position: 'relative'
                                }}>
                                    <Image asset={image} size={{
                                        width: 20,
                                        height: 20
                                    }} />
                                </div>
                            )}
                            {getLinkTitle()}
                            {icon && icon}
                        </>
                    )}
                </a >
            )}
        </>
    )
}

export default CustomLink